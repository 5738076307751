<template>
  <div class="mt-4">
    <h2 class="mb-5">{{ $t('Payment.membership.header') }}</h2>
    <p v-html="$t('Payment.membership.content')"></p>

    <ValidationProvider v-slot="memberCheck" ref="memberCheck" name="membership confirmation" rules="required">
      <b-form-group id="input-group-member-check" class="mb-1" label-for="input-member-check">
        <b-form-radio-group :key="radioKey" v-model="membershipSelection">
          <b-form-radio
            :state="getValidationState(memberCheck)"
            aria-describedby="input-group-member-check-feedback"
            :value="true"
          >
            <p class="mb-1">{{ $t('Payment.membership.checkbox1') }}</p>
          </b-form-radio>
          <p class="disclaimer">{{ $t('Payment.membership.checkbox1Disclaimer') }}</p>

          <b-form-radio :state="getValidationState(memberCheck)" :value="false">
            <p class="mb-1">{{ $t('Payment.membership.checkbox2') }}</p>
          </b-form-radio>
        </b-form-radio-group>

        <b-form-invalid-feedback id="input-group-member-check-feedback">
          {{ memberCheck.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { Customer } from '@/lib/schema';

interface Flags {
  [k: string]: boolean | null;
}

export default Vue.extend({
  name: 'MemberCheck',
  props: {
    customer: {
      type: Object as PropType<Customer>,
      required: true
    }
  },
  data() {
    return {
      membershipSelection: null as boolean | null,
      radioKey: 0
    };
  },
  watch: {
    membershipSelection(value) {
      this.$emit('update:isMember', value);

      if (value === false) {
        (this.$refs.memberCheck as any)?.reset();
      }
    }
  },
  mounted() {
    // Ensure null on mount so that neither button is selected
    if (this.customer.isMember !== true && this.customer.isMember !== false) {
      this.membershipSelection = null;
      this.$emit('update:isMember', null);
      this.radioKey++; // Force re-render to prevent BootstrapVue auto-selection
    }
  },
  methods: {
    getValidationState({ validated, valid = null }: Flags) {
      return validated ? valid : null;
    }
  }
});
</script>

<style lang="scss">
.disclaimer {
  color: #767676;
  padding-left: 0.75rem;
  font-size: 14px;
  line-height: 1.2;
}
</style>
