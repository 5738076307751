<template>
  <div v-if="ticketPackage.availableForPurchaseV2" class="tickets">
    <p class="tickets__title">
      {{ ticketPackage.numTickets }} {{ $t('for') }}
      {{
        $i18n.locale === 'fr'
          ? formatCurrency(ticketPackage.price, { precision: 0, locale: 'fr' })
          : formatCurrency(ticketPackage.price, { precision: 0 })
      }}
    </p>
    <div v-if="ticketPackage.availableForPurchaseV2 !== 'soldout'" class="tickets__controls">
      <button class="btn btn-light btn--minus" @click="remove">
        <b-icon-dash />
      </button>
      <span class="tickets__total">{{ quantity }}</span>
      <button class="btn btn-success btn--plus" @click="add">
        <b-icon-plus />
      </button>
    </div>
    <div v-else>
      <p class="tickets__controls--soldout">Sold Out</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { TicketPackage as TicketPackageObject } from '@/lib/schema';
import ProjectMixin from '@/project-mixin';

@Component
export default class TicketPackage extends Mixins(ProjectMixin) {
  @Prop({ required: true, default: null })
  ticketPackage!: TicketPackageObject;

  quantity = 0;

  get addon() {
    return this.$store.state.addon;
  }

  mounted() {
    this.quantity = this.shoppingCart.items().find((tp) => tp.id === this.ticketPackage.id)?.quantity || 0;
  }

  @Watch('addon')
  onAddonChanged(newAddon: any, oldAddon: any) {
    if (newAddon !== oldAddon) {
      this.quantity = this.shoppingCart.items().find((tp) => tp.id === this.ticketPackage.id)?.quantity || 0;
    }
  }

  async add() {
    const id = this.$route.name === 'Addon' ? this.$store.state.addon.id : this.$store.state.event.id;
    const name = this.$route.name === 'Addon' ? this.$store.state.addon.name : this.$store.state.event.name;

    const item = this.shoppingCart.add({
      id: this.ticketPackage.id,
      eventId: id,
      eventName: name,
      quantity: 1,
      priceCents: this.ticketPackage.price * 100,
      series: this.ticketPackage.series,
      numTickets: this.ticketPackage.numTickets
    });

    this.quantity = item.quantity;
  }

  async remove() {
    this.quantity = this.shoppingCart.remove(this.ticketPackage.id)?.quantity || 0;
  }
}
</script>

<style lang="scss">
.tickets {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 -1px;
  padding: 1rem;
  font-size: 1.25rem;
  border-top: 1px solid $smoke;
  border-bottom: 1px solid $smoke;

  &:last-child {
    border-bottom: 0;
  }

  &__title {
    font-family: $heading;
    margin: 0;
  }

  &__controls {
    display: flex;
    flex: 0 1 45%;
    justify-content: space-between;
    align-items: center;
    max-width: 11rem;

    &--soldout {
      margin: 0;
      padding: 0.5rem;
      color: $white;
      background-color: $dangerRed;
    }
  }

  &__total {
    display: block;
    text-align: center;
  }

  .btn {
    touch-action: manipulation;

    &:hover,
    &:focus {
      box-shadow: none;
    }

    &--plus {
      background-color: var(--buttonColor);
      border-color: var(--buttonColor);

      &:active,
      &:hover,
      &:focus {
        color: var(--buttonColor) !important;
        background-color: $white !important;
        border-color: var(--buttonColor) !important;
      }
    }

    &--plus,
    &--minus {
      padding: 0.25rem 0.5rem;
    }
  }
}
</style>
