export default {
  API_URL: process.env.VUE_APP_API_URL as string,
  PLAYER_PORTAL_ENV: process.env.VUE_APP_PLAYER_PORTAL_ENVIRONMENT as string,
  API_CACHE_URL: process.env.VUE_APP_API_CACHE_URL as string,
  PAYMENT_API_URL: process.env.VUE_APP_PAYMENT_API_URL as string,
  TICKET_API_URL: process.env.VUE_APP_TICKET_API_URL as string,
  VERSION: 'v1.0.0',
  GIT_COMMIT: process.env.VUE_APP_GIT_COMMIT as string,
  DEPLOY_TIME: process.env.VUE_APP_DEPLOY_TIME,
  CLIENT: 'PAYMENT',
  ENVIRONMENT: process.env.VUE_APP_ENVIRONMENT as string,
  STRIPE_PUBLIC_CA_KEY: process.env.VUE_APP_STRIPE_CA_PUBLIC_KEY,
  STRIPE_PUBLIC_US_KEY: process.env.VUE_APP_STRIPE_US_PUBLIC_KEY,
  RAFFLEBOX_URL: process.env.VUE_APP_RAFFLEBOX_URL as string,
  RAFFLEBOX_IMAGES_URL: process.env.VUE_APP_RAFFLEBOX_IMAGES_URL,
  ENV: process.env.VUE_APP_ENVIRONMENT,
  GOOGLE_TAG_MANAGER_CONTAINER_ID: process.env.VUE_APP_GOOGLE_TAG_MANAGER_CONTAINER_ID as string,
  IPSTACK_API_TOKEN: process.env.VUE_APP_IPSTACK_API_TOKEN as string,
  UNLEASH_CLIENT_KEY: process.env.VUE_APP_UNLEASH_KEY,
  PLAYER_PORTAL_URL: process.env.VUE_APP_PLAYER_PORTAL_URL as string,
  LDH_PRIVACY_POLICY_ORG_ID: process.env.VUE_APP_LDH_PRIVACY_POLICY_ORG_ID as string
};
