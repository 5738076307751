<template>
  <ValidationProvider>
    <b-form-group id="input-group-age" label-for="age" label="Age">
      <b-form-select v-model="selectedAge" name="age" :options="ageRangeOptions" data-vv-as="age"></b-form-select>
    </b-form-group>
  </ValidationProvider>
</template>

<script lang="ts">
import Vue from 'vue';

const ageRanges = [
  { value: null, text: '--- Select an age range ---' },
  { value: '18-24', text: '18-24' },
  { value: '25-34', text: '25-34' },
  { value: '35-44', text: '35-44' },
  { value: '45-54', text: '45-54' },
  { value: '55-64', text: '55-64' },
  { value: '65+', text: '65+' }
];

export default Vue.extend({
  name: 'AgeSelect',
  data() {
    return {
      selectedAge: null,
      ageRangeOptions: ageRanges
    };
  },
  watch: {
    selectedAge() {
      this.$emit('age-input', this.selectedAge);
    }
  }
});
</script>
