<template>
  <div>
    <div class="ticket-discount__message">
      <p>Ticket prices:</p>
      <ul>
        <li><s>1 or 2 tickets: $25 each</s> <span class="sold-out">Sold out</span></li>
        <li>3 (or more) tickets: $20 each</li>
      </ul>
    </div>
    <div class="tickets">
      <p class="tickets__title">
        Number of Tickets
        <b-badge v-if="discount" variant="info">Discount applied</b-badge>
      </p>
      <div v-if="!discountPackage.soldOut" class="tickets__controls">
        <button class="btn btn-light btn--minus" @click="remove">
          <b-icon-dash />
        </button>
        <span class="tickets__total">{{ quantity }}</span>
        <button class="btn btn-success btn--plus" @click="add">
          <b-icon-plus />
        </button>
      </div>
      <div v-else>
        <p class="tickets__controls--soldout">Sold Out</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ProjectMixin from '@/project-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import { TicketPackage as TicketPackageObject } from '@/lib/schema';
@Component
export default class SPCADiscountTickets extends Mixins(ProjectMixin) {
  discount = false;
  quantity = 3;
  ticketPackage: TicketPackageObject = this.$store.state.event.ticketPackages[0];
  discountPackage: TicketPackageObject = this.$store.state.event.ticketPackages[1];
  mounted() {
    this.checkout.updateCurrentPackage(this.discountPackage);

    this.quantity = this.shoppingCart.items().find((tp) => tp.id === this.checkout.currentPackage.id)?.quantity || 0;
    // Apply discount flag on 3+
    this.discount = this.quantity >= 3 ? true : false;
  }
  async add() {
    // You can buy 0 tickets or 3+.
    const numPackage = this.quantity < 3 ? 3 : 1;

    const item = this.shoppingCart.add(
      {
        id: this.checkout.currentPackage.id,
        eventId: this.$store.state.event.id,
        eventName: this.$store.state.event.name,
        quantity: 1,
        priceCents: this.checkout.currentPackage.price * 100,
        series: this.checkout.currentPackage.series,
        numTickets: this.checkout.currentPackage.numTickets
      },
      numPackage
    );

    this.quantity = item.quantity;
    this.discount = this.quantity >= 3 ? true : false;
  }
  async remove() {
    // If 3 ticket packages then remove all of them.
    if (this.quantity === 0) {
      return;
    }
    if (this.quantity === 3) {
      this.shoppingCart.removeAllById(this.checkout.currentPackage.id);
      this.quantity = 0;
    } else {
      this.shoppingCart.remove(this.checkout.currentPackage.id);
      this.quantity = this.quantity - 1;
    }
    this.discount = this.quantity >= 3 ? true : false;
  }
}
</script>

<style lang="scss" scoped>
.ticket-discount {
  &__message {
    font-size: 1.15rem;

    p {
      margin-bottom: 0.5rem;
    }

    ul {
      margin-bottom: 2rem;
    }

    .sold-out {
      display: inline-block;
      padding: 0.2rem 0.5rem;
      background-color: $dangerRed;
      color: white;
    }
  }
}
</style>
