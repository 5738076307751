export interface TicketPackage {
  id: string;
  numTickets: number;
  availableForPurchase: boolean;
  availableForPurchaseV2: boolean | string;
  series: string;
  price: number;
  soldOut: boolean;
}

export enum Merchant {
  Bambora = 'bambora',
  Stripe = 'stripe',
  StripeConnect = 'stripe_connect'
}

export enum EventStatus {
  Pending = 'pending',
  Active = 'active',
  Ended = 'ended',
  Closed = 'closed'
}

export enum EventCheckoutType {
  single = 'SINGLE',
  multiraffle = 'MULTIRAFFLE',
  goldrush = 'GOLDRUSH'
}
export interface EventSettings {
  geoLocation: boolean;
  allowSecondaryName: boolean;
  whiteLabel: WhiteLabelSettings;
  pixelCode: string;
  requirePurchase: boolean;
  disableOnlineSales: boolean;
  gtmCode: string;
}

export interface WhiteLabelSettings {
  enabled: boolean;
  buttonColor: string;
  checkoutBannerUrl?: string;
  redirectUrl?: string;
}
export interface Event {
  id: string;
  name: string;
  category: string;
  ticketPackages: TicketPackage[];
  numTickets: number;
  country: string;
  province: string;
  number: number;
  logoUrl: string;
  eventMemberId?: number;
  memberName?: string;
  shortlink: string;
  merchant: Merchant;
  status: EventStatus;
  organizationId: string;
  stripeConnectedAccountId: string;
  salesEnabled: boolean;
  disablePostalCheck: boolean;
  disablePostalCheckV2: boolean;
  pixelCode: string;
  settings: EventSettings;
  childEvents: Event[];
  soldOut: boolean;
  goldrush?: object;
  processingFeePercent?: number;
  licenseNumber?: string;
  isHomeLottery?: boolean;
  thresholdReached?: boolean;
}

export interface GoldrushMasterEvent {
  activated: boolean;
  daysPerDraw: number;
  description: string;
  endDate: string;
  id: string;
  licenseNumber?: string;
  name: string;
  numDraws: number;
  startDate: string;
  ticketPackages: TicketPackage[];
}
