<template>
  <div class="checkout-page">
    <div class="raffle-title">
      <h2>{{ event.name }}</h2>
      <CircleNav
        v-if="canViewMultiAddOnFlowOrgs && !event.isHomeLottery && (!event.thresholdReached || !childThresholdReached)"
      />
    </div>
    <ThresholdReached v-if="event.isHomeLottery && (event.thresholdReached || childThresholdReached)" />
    <div v-else>
      <InSupport />
      <div v-if="$store.state.specialEvent" class="ticket-packages">
        <SPCADiscountTickets />
      </div>
      <div v-else class="ticket-packages">
        <h4 class="text-regular mb-3">{{ $t('ticketPackages') }}</h4>
        <TicketPackage
          v-for="ticketPackage of event.ticketPackages"
          :key="ticketPackage.series"
          :ticket-package="ticketPackage"
          :event-id="event.id"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import InSupport from '@/components/InSupport.vue';
import TicketPackage from '@/components/TicketPackage.vue';
import SPCADiscountTickets from '@/components/SPCADiscountTicketsOnly.vue';
import ProjectMixin from '@/project-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import checkout from '@/model/checkout';
import { Event } from '@/lib/schema/event.schema';
import { eventMemberCheck } from '@/lib/helpers';
import EventMemberServiceV2 from '@/lib/services/event-member-service-v2';
import CircleNav from '@/components/CircleNav.vue';
import ThresholdReached from '@/components/ThresholdReached.vue';

import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

@Component({
  components: {
    TicketPackage,
    InSupport,
    SPCADiscountTickets,
    CircleNav,
    ThresholdReached
  }
})
export default class Raffle extends Mixins(ProjectMixin) {
  event: Event = this.$store.state.event;
  eventMemberNumber = -1;
  canViewMultiAddOnFlowOrgs = false;
  childThresholdReached = false;

  created() {
    // On back button force page refresh.
    window.onpopstate = function () {
      location.reload();
    };
  }

  async mounted() {
    if (this.$route.query.eventMemberNumber) {
      checkout.selectedEventMember = eventMemberCheck(this.$route.query.eventMemberNumber as string);
    }
    this.eventMemberNumber = checkout.selectedEventMember ?? -1;

    if (this.eventMemberNumber !== -1) {
      this.fetchEventMembers();
    }

    this.canViewMultiAddOnFlowOrgs = await unleashFeatureEnabled(UnleashKeys.ViewMultiAddOnFlowOrgs);

    this.childThresholdReached = this.event.childEvents.some((childEvent) => childEvent.thresholdReached);
  }

  async fetchEventMembers() {
    if (this.event) {
      const selectedMember = await EventMemberServiceV2.getSelectedEventMember(
        this.eventMemberNumber,
        this.event.number
      );

      if (selectedMember) {
        if (selectedMember.groupName !== undefined) {
          checkout.selectedEventMemberName = `${selectedMember.groupName}, ${selectedMember.memberName}`;
        } else {
          checkout.selectedEventMemberName = `${selectedMember.memberName}`;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  font-family: $heading;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1rem 0 1.5rem;
}

.in-support-of {
  margin-bottom: 1.5rem;

  .required {
    color: $dangerRed;
  }
}

.ticket-packages {
  margin-bottom: 2rem;

  .tickets {
    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

.raffle-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// iPad Portrait
@media screen and (min-width: 48rem) {
  h2 {
    font-size: 3rem;
    margin: 2rem 0 3rem;
  }

  .ticket-packages {
    margin-bottom: 4rem;
  }
}
</style>
